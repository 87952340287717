import { Player } from '@lottiefiles/react-lottie-player';
import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import Button from '@design0.2/Button/Button';
import { EVENT_TAG } from '@global/constants';
import {
  logFirebase,
  useLogFirebase,
} from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import ROUTE from '@routers';
import { styled } from '@styles/stitches.config';
import KemimojiArt from '@user/assets/lottie-kemimoji-art.json';
import KemimojiLaptop from '@user/assets/lottie-kemimoji-laptop.json';
import KemimojiMoney from '@user/assets/lottie-kemimoji-money.json';
import KemimojiShopping from '@user/assets/lottie-kemimoji-shopping.json';
import OnboardingTitle from '@user/components/Onboarding/OnboardingTitle';
import Tip from '@user/components/Onboarding/Tip';
import { OnboardingValues } from '@user/components/Onboarding/validator';

type OnboardingResultProps = {
  isOldOnboardedUser: boolean;
  onButtonClick: () => void;
  isLoading: boolean;
};

function OnboardingResult({
  isOldOnboardedUser,
  onButtonClick,
  isLoading,
}: OnboardingResultProps) {
  const router = useRouter();
  const { values } = useFormikContext<OnboardingValues>();

  const linkName = values.linkName;
  const kemiUsageType = values.kemiUsageType;

  const title1 = isOldOnboardedUser
    ? `${i18n.t('k_name_nim', { name: values.linkName })},`
    : i18n.t('k_all_answers_are_completed');
  const title2 = isOldOnboardedUser
    ? i18n.t('k_thank_you_for_your_answers')
    : '';

  const { buttonText, href, lottieJson, tip } = useMemo(() => {
    switch (kemiUsageType) {
      case 'SELLER':
        return {
          buttonText: i18n.t('k_apply_product_new'),
          href: ROUTE.sellerCenter.products.new,
          lottieJson: KemimojiMoney,
          tip: i18n.t('k_onboarding_seller_tip_text'),
        };
      case 'CREATOR':
        return {
          buttonText: i18n.t('k_apply_commission_new'),
          /**
           * 커미션 등록 페이지에서 개인정보등록 페이지로 리디렉션 하는 과정에서
           * USER FLOW가 정리가 되지 않아 Card-Type 페이지로 랜딩한다.
           */
          href: ROUTE.mykemi.cardType,
          lottieJson: KemimojiArt,
          tip: i18n.t('k_onboarding_creator_tip_text'),
        };
      case 'MULTILINK':
        return {
          buttonText: i18n.t('k_apply_link_card_new'),
          href: ROUTE.mykemi.links.add,
          lottieJson: KemimojiLaptop,
          tip: i18n.t('k_onboarding_multilink_tip_text'),
        };
      default:
        return {
          buttonText: i18n.t('k_add_product_card_new'),
          href: ROUTE.mykemi.products.list,
          lottieJson: KemimojiShopping,
          tip: i18n.t('k_onboarding_etc_tip_text'),
        };
    }
  }, [kemiUsageType]);

  const handleClose = async () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.ONBOARDING.ONBOARDING_DONE_DONE_CLICK,
      {
        type: isOldOnboardedUser ? 'old' : 'new',
        useType: values.kemiUsageType,
      }
    );
    await onButtonClick();
  };

  const handleRoute = async () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.ONBOARDING.ONBOARDING_DONE_TRY_CLICK,
      {
        type: isOldOnboardedUser ? 'old' : 'new',
        useType: values.kemiUsageType,
      }
    );
    await onButtonClick();
    router.push(href);
  };

  useLogFirebase(
    UserInteractionType.PAGE_IN,
    EVENT_TAG.ONBOARDING.SCREEN_OPEN_ONBOARDING_DONE,
    { type: isOldOnboardedUser ? 'old' : 'new', useType: values.kemiUsageType }
  );

  return (
    <Container>
      <OnboardingTitle title1={title1} title2={title2} />
      <TipContainer>
        <Tip text={tip} />
      </TipContainer>
      <Kemimoji>
        <Player autoplay loop src={lottieJson} />
      </Kemimoji>
      <Caption>
        {i18n.t('k_welcome_name_kemi_good', { name: linkName })}
      </Caption>
      <Buttons>
        <Button type={'button'} onClick={handleRoute} loading={isLoading}>
          {buttonText}
        </Button>
        <Button
          buttonStyle={'tertiary'}
          type={'button'}
          onClick={handleClose}
          loading={isLoading}
        >
          {i18n.t('k_go_to_home')}
        </Button>
      </Buttons>
    </Container>
  );
}

const Container = styled('div', {
  height: '100%',
  paddingTop: 80,
  paddingBottom: 56,
});

const TipContainer = styled('div', {
  marginTop: 16,
});

const Kemimoji = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 64,
  marginBottom: 24,
});

const Caption = styled('h4', {
  fontType: 'titleBold',
  wordBreak: 'keep-all',
  paddingBottom: 64,
});

const Buttons = styled('div', {
  gridRowWithGap: 8,
  paddingY: 16,
  backgroundColor: '$white',
});

export default OnboardingResult;
