import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { EVENT_TAG } from '@global/constants';
import useUserQuery from '@global/queries/useUserQuery';
import ChannelTalk from '@global/service/ChannelTalk';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import useChannelTalkOptionStore from '@pages/app/store/useChannelTalkOptionStore';
import ROUTE, { ROUTE_QUERY } from '@routers';

const CHANNEL_TALK_ZINDEX = 40;

// 채널톡 초기화가 필요한 페이지 라우트를 추가해주세요
const ALLOWED_PATH = new Set([
  ROUTE.mykemi.index,
  ROUTE.mykemi.home,
  ROUTE.commissions.index,
  ROUTE.products.product,
  `/products2/[${ROUTE_QUERY.productCardId}]`,
  ROUTE.orders.index,
  ROUTE.orders.checkout.index,
  ROUTE.debugMode,
]);

// 채널톡 버튼을 숨겨야 하는 경우 추가해주세요
const HIDE_BUTTON_PATH = new Set([
  ROUTE.orders.checkout.index,
  ROUTE.debugMode,
]);

const pluginKey = process.env.NEXT_PUBLIC_CHANNEL_TALK_PLUGIN_KEY as string;
const adminHostName = process.env.NEXT_PUBLIC_ADMIN_HOST_NAME as string;

function useChannelTalkBoot() {
  const router = useRouter();
  const { hideChannelTalkButton } = useChannelTalkOptionStore();

  const { data: user, isLoading: isUserLoading } = useUserQuery();

  /**
   * 유저정보 업데이트 (boot or update)
   */
  useEffect(() => {
    if (isUserLoading) return;

    const profile = user
      ? {
          email: user.profile.email,
          mobileNumber: user.profile.phoneNumber,
          name: user.profile.name,
          isKemiUser: true,
          kemiName: user.title,
          kemiLinkName: user.linkName,
          mktAgree: user.profile.allowMarketingNotification,
          orderLineAdminUrl: `${adminHostName}/order-line-items?ORDER_USER_ID=${user?.userId}`,
          kemiUsageType: user.userType.kemiUsageType,
          productToSellType: user.userType.productToSellType,
          snsUsageType: user.userType.snsUsageType,
        }
      : undefined;

    if (ChannelTalk.isBootDone) {
      ChannelTalk.updateUser({
        profile,
      });
      return;
    }

    ChannelTalk.boot({
      pluginKey,
      zIndex: CHANNEL_TALK_ZINDEX,
      hideChannelButtonOnBoot: true,
      memberId: user?.userId,
      profile,
    });
  }, [isUserLoading, user]);

  /**
   * 원하는 조건에 따라 숨김/보임 처리 이펙트
   */
  useEffect(() => {
    const isDisallowPath = !ALLOWED_PATH.has(router.pathname);
    const isHidePath = HIDE_BUTTON_PATH.has(router.pathname);

    if (isDisallowPath || isHidePath || hideChannelTalkButton) {
      ChannelTalk.hideChannelButton();
      return;
    }

    ChannelTalk.showChannelButton();
  }, [router.pathname, hideChannelTalkButton]);

  /**
   * 페이지별로 다르게 처리해야하는 로직
   */
  useEffect(() => {
    if (!router.isReady) return;

    if (router.pathname === ROUTE.orders.index) {
      ChannelTalk.onShowMessenger(() => {
        logFirebase(
          UserInteractionType.CLICK,
          EVENT_TAG.KEMI_ORDERDETAIL.KEMI_ORDERDETAIL_CLICK_INQUIRY
        );
      });
    }

    if (router.pathname === ROUTE.orders.checkout.success) {
      const orderId = router.query.orderId as string;
      ChannelTalk.updateUser({ profile: { lastOrderId: orderId } });
    }
  }, [router.pathname, router.isReady]);
}

export default useChannelTalkBoot;
