import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';

import InternalError from './InternalError';

import NotFoundErrorPage from '@pages/404/index.page';
import Wrapper from '@pages/app/Wrapper';
import NotFoundError from '@shared/errors/NotFoundError';

type ErrorBoundaryProps = {
  children: ReactNode;
};

const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children } = props;

  return (
    <SentryErrorBoundary
      fallback={({ error }) => {
        if (error instanceof NotFoundError) {
          return <NotFoundErrorPage />;
        }

        return (
          <Wrapper>
            <InternalError />
          </Wrapper>
        );
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export default ErrorBoundary;
