import { array, boolean, object, string } from 'yup';

import { BANNER_TITLE_MAX_LENGTH, LINK_NAME_MAX_LENGTH } from './setting';

import i18n from '@i18n/index';
import {
  CARD_TITLE_MAX_LENGTH,
  MEDIA_CARD_TITLE_MAX_LENGTH,
} from '@shared/constants/multilink';

export const linkCardFormSchema = object({
  title: string()
    .max(
      CARD_TITLE_MAX_LENGTH,
      i18n.t('k_too_much_enter_content', { count: CARD_TITLE_MAX_LENGTH })
    )
    .required(i18n.t('k_required_entry')),
  url: string().required(i18n.t('k_required_entry')),
  showBadge: boolean(),
  badges: array().when('showBadge', {
    is: true,
    then: () =>
      array().of(
        object().shape({
          bgColor: string().required(i18n.t('k_required_entry')),
          title: string().required(i18n.t('k_required_entry')),
        })
      ),
  }),
  imageUrl: string().when('showImage', {
    is: true,
    then: () =>
      string().when('cardImageType', {
        is: 'EMOJI',
        otherwise: () =>
          string().required(i18n.t('k_should_upload_image_please')),
        then: () => string(),
      }),
  }),
  emoji: string().when('showImage', {
    is: true,
    then: () =>
      string().when('cardImageType', {
        is: 'EMOJI',
        then: () => string().required(i18n.t('k_please_fill_in_emoji')),
      }),
  }),
});

export const imageCardFormSchema = object({
  imageUrls: array().of(string()).min(1, i18n.t('k_add_image_notice_message')),
  link: object().shape({
    useLink: boolean(),
    linkUrl: string().when('useLink', {
      is: true,
      then: () => string().required(i18n.t('k_required_entry')),
    }),
  }),
  button: object().shape({
    showButton: boolean(),
    text: string().when('showButton', {
      is: true,
      then: () => string().required(i18n.t('k_required_entry')),
    }),
  }),
  text: object().shape({
    showText: boolean(),
    header: string()
      .max(30, i18n.t('k_too_much_enter_content', { count: 30 }))
      .when('showText', {
        is: true,
        then: () => string().required(i18n.t('k_required_entry')),
      }),
    content: string().max(
      50,
      i18n.t('k_too_much_enter_content', { count: 50 })
    ),
  }),
  badge: object().shape({
    showBadge: boolean(),
    text: string().when('showBadge', {
      is: true,
      then: () => string().required(i18n.t('k_required_entry')),
    }),
  }),
});

export const textCardFormSchema = object({
  title: string()
    .max(500, i18n.t('k_too_much_enter_content', { count: 500 }))
    .required(i18n.t('k_required_entry')),
});

export const productCardFormSchema = object({
  title: string()
    .max(
      CARD_TITLE_MAX_LENGTH,
      i18n.t('k_too_much_enter_content', { count: CARD_TITLE_MAX_LENGTH })
    )
    .required(i18n.t('k_required_entry')),
  showBadge: boolean(),
  badges: array().when('showBadge', {
    is: true,
    then: () =>
      array().of(
        object().shape({
          bgColor: string().required(i18n.t('k_required_entry')),
          title: string().required(i18n.t('k_required_entry')),
        })
      ),
  }),
  showImage: boolean().when('layoutStyle', {
    is: 'MULTI_COLUMN',
    then: () => boolean().oneOf([true], i18n.t('k_should_upload_image_please')),
  }),
  imageUrl: string().when('showImage', {
    is: true,
    then: () =>
      string().when('imageType', {
        is: 'EMOJI',
        otherwise: () =>
          string().required(i18n.t('k_should_upload_image_please')),
      }),
  }),
  emoji: string().when('showImage', {
    is: true,
    then: () =>
      string().when('imageType', {
        is: 'EMOJI',
        then: () => string().required(i18n.t('k_please_fill_in_emoji')),
      }),
  }),
});

export const videoCardFormSchema = object({
  title: string()
    .max(
      MEDIA_CARD_TITLE_MAX_LENGTH,
      i18n.t('k_too_much_enter_content', { count: MEDIA_CARD_TITLE_MAX_LENGTH })
    )
    .required(i18n.t('k_required_entry')),
  videoUrl: string().required(i18n.t('k_required_entry')),
  badges: array().when('showBadge', {
    is: true,
    then: () =>
      array().of(
        object().shape({
          bgColor: string().required(i18n.t('k_required_entry')),
          title: string().required(i18n.t('k_required_entry')),
        })
      ),
  }),
  autoPlay: boolean().when('mutePlay', {
    is: false,
    then: () => boolean().equals([false]),
  }),
});

export const musicCardFormSchema = object({
  title: string()
    .max(
      MEDIA_CARD_TITLE_MAX_LENGTH,
      i18n.t('k_too_much_enter_content', { count: MEDIA_CARD_TITLE_MAX_LENGTH })
    )
    .required(i18n.t('k_required_entry')),
  musicUrl: string().required(i18n.t('k_required_entry')),
  badges: array().when('showBadge', {
    is: true,
    then: () =>
      array().of(
        object().shape({
          bgColor: string().required(i18n.t('k_required_entry')),
          title: string().required(i18n.t('k_required_entry')),
        })
      ),
  }),
});

export const linkNameSchema = string()
  .required(i18n.t('k_required_entry'))
  .min(2, i18n.t('k_at_least_2_characters'))
  .max(LINK_NAME_MAX_LENGTH, i18n.t('k_cant_be_longer_than_20_characters'))
  .matches(/^[a-z0-9_]*$/, i18n.t('k_at_least_2_characters'));

export const profileFormSchema = object({
  title: string().required(i18n.t('k_required_entry')),
  linkName: linkNameSchema,
});

export const bannerFormSchema = object({
  linkName: linkNameSchema,
  bannerTitle: string().max(
    BANNER_TITLE_MAX_LENGTH,
    i18n.t('k_too_much_enter_content', { count: BANNER_TITLE_MAX_LENGTH })
  ),
  imageUrls: array().of(string()).min(1, i18n.t('k_add_image_notice_message')),
  link: object().shape({
    useLink: boolean(),
    linkUrl: string().when('useLink', {
      is: true,
      then: () => string().required(i18n.t('k_required_entry')),
    }),
  }),
  button: object().shape({
    showButton: boolean(),
    text: string().when('showButton', {
      is: true,
      then: () => string().required(i18n.t('k_required_entry')),
    }),
  }),
  text: object().shape({
    showText: boolean(),
    header: string()
      .max(30, i18n.t('k_too_much_enter_content', { count: 30 }))
      .when('showText', {
        is: true,
        then: () => string().required(i18n.t('k_required_entry')),
      }),
    content: string().max(
      50,
      i18n.t('k_too_much_enter_content', { count: 50 })
    ),
  }),
  badge: object().shape({
    showBadge: boolean(),
    text: string().when('showBadge', {
      is: true,
      then: () => string().required(i18n.t('k_required_entry')),
    }),
  }),
});
